import axiosClient from '../index'

export const userLibs = {
  detail() {
    return axiosClient.get('/v1/user/profile')
  },
  updateEmail(payload){
    return axiosClient.put('/v1/user/email', payload)
  },
  updatePassword(payload){
    return axiosClient.put('/v1/user/password', payload)
  },
  generateApiKey(){
    return axiosClient.post('/v1/user/generate-api-key')
  }
}
