<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <!-- <CHeaderBrand class="d-lg-none" to="/">
        <img class="d-block" height="38" :src="logo"/>
      </CHeaderBrand> -->
      <CHeaderNav class="align-items-center">
        <CNavItem>
          <CButton 
            color="info"
            variant="outline"
            class="btn-sm me-2 px-2 font-11"
            style="font-weight: 500;"
            @click="refreshSaldo($event)"
          >{{ txtBtnRefreshSaldo }}</CButton>
        </CNavItem>
        <CNavItem >
          <CBadge color="info" class="font-13">Saldo Rp. {{saldo}}</CBadge>
        </CNavItem>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import logo from '@/assets/brand/lakusmm.png'
import { userLibs } from '@/api/libs/user'
import formatRupiah from '@/utils/format-rupiah'
export default {
  name: 'AppHeader',
  components: {
    AppHeaderDropdownAccnt,
  },
  setup() {
    return {
      logo,
      formatRupiah,
    }
  },
  data() {
    const userSession = JSON.parse(window.localStorage.getItem('auth'))
    const userId = userSession.id
    return {
      saldo: 0,
      userId: userId,
      txtBtnRefreshSaldo : 'Refresh Saldo',
    }
  },
  mounted(){
    this.user() 
  },
  methods: {
    user(){
      userLibs
        .detail()
        .then((response) => {
          let saldo = response.data.data.fund
          if(saldo == 0){
            this.txtBtnRefreshSaldo = 'Isi Saldo'
          }
          this.saldo = formatRupiah(parseInt(saldo))
        })
        .catch((e) => {
          console.log(e)
        })
    },
    refreshSaldo(){
      this.user()
    }
  },
}
</script>
