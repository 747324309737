import {
  h,
  resolveComponent
} from 'vue'
import {
  createRouter,
  createWebHashHistory
} from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [{
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/new-order',
    children: [{
        path: '/new-order',
        name: 'NewOrder',
        component: () => import('@/views/pages/NewOrder.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/mass-order',
        name: 'MassOrder',
        component: () => import('@/views/pages/MassOrder.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/service',
        name: 'Service',
        component: () => import('@/views/pages/Services.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/order',
        name: 'Order',
        component: () => import('@/views/pages/Orders.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/add-fund',
        name: 'AddFund',
        component: () => import('@/views/pages/AddFunds.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/ticket',
        name: 'Ticket',
        component: () => import('@/views/pages/Tickets.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/api',
        name: 'Api',
        component: () => import('@/views/pages/Api.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/account',
        name: 'Account',
        component: () => import('@/views/pages/Account.vue'),
        meta: {
          auth: true
        }
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [{
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login/:register?',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
        meta: {
          isLogin: true
        }
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
        meta: {
          isLogin: true
        }
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  },
})

router.beforeEach((to, from, next) => {
  let authToken = JSON.parse(window.localStorage.getItem('auth'));
  if (to.matched.some(record => record.meta.auth)) {
    if (!authToken) {
      next({ name: 'Login' });
    } else {
      next();
    }
  } else {
    next(); // Very important to call next() in this case!
  }
})
router.beforeResolve((to, from, next) => {
  let authToken = JSON.parse(window.localStorage.getItem('auth'));
  if (to.matched.some(record => record.meta.isLogin)) {
    if (authToken) {
      next({ name: 'Home' });
    } else {
      next();
    }
  } else {
    next(); // Very important to call next() in this case!
  }
})

export default router
