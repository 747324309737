<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand>
      <img class="d-block" height="40"  :src="logo"/>
    </CSidebarBrand>
    <AppSidebarNav />
    <a href="https://wa.me/62859106642630" target="_blank" style="bottom: 60px;right: 40px;position: fixed;">
      <img class="d-block" width="70"  :src="wa"/>
    </a>
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { sygnet } from '@/assets/brand/sygnet'
import logo from '@/assets/brand/lakusmm.png'
import wa from '@/assets/images/wa.png'
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore()
    return {
      logo,
      wa,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>
