<template>
  <CFooter>
    
    <div class="ms-auto">
      <span class="me-1" target="_blank">Panel SMM by</span>
      <a href="https://lakusmm.com">LakuSMM</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
