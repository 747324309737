<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownItem>
        <CNavItem>
          <CNavLink style="cursor: pointer;" @click="account" >
            Profile
          </CNavLink>
        </CNavItem>
      </CDropdownItem>
      <CDropdownItem>
        <CNavLink style="cursor: pointer;" @click="logout">
          Logout
        </CNavLink>
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/user_icon.png'
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    }
  },
  methods: {
    logout(){
      localStorage.removeItem('auth');
      this.$router.push({ name: 'Login' })
    },
    account(){
      this.$router.push({ name: 'Account' })
    }
  }
}
</script>
