import axios from 'axios'

const host = window.location.protocol + '//' + window.location.host

const axiosClient = axios.create({
  baseURL: 'https://api.lakusmm.com',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})
axiosClient.interceptors.request.use(function (config) {
  const userSession = getSavedState('auth');
  if(userSession){
    // if(config.refresh){
    //   const token = userSession.refreshToken.token
    //   config.headers.Authorization = 'Bearer '+ token;
    // }else{
      // const token = userSession.accessToken.token
      const token = userSession.token
      config.headers.Authorization = 'Bearer '+ token;
    // }
  }

  // config.headers.en = 'id'; 
  return config;
});
axiosClient.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    let res = error.response
    if (res.status === 401 && window.location.pathname !== '/login') {
      localStorage.removeItem('auth');
      window.location.href = `${window.location.origin}/#/pages/login`
    }
    return Promise.reject(error)
  }
)
function getSavedState(key) {
  return window.localStorage.getItem(key)
    ? JSON.parse(window.localStorage.getItem(key))
    : null
}

export default axiosClient
